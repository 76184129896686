<template>
    <div class="markers">
        <div class="stateStatusCircles">
            <div
                :class="{ active: activeStep.step === 1, prev: activeStep.step > 1 }"
                class="stateCircle"
            ></div>
            <div
                :class="{ active: activeStep.step === 2, prev: activeStep.step > 2 }"
                class="stateCircle"
            ></div>
            <div
                :class="{ active: activeStep.step === 3, prev: activeStep.step > 3 }"
                class="stateCircle"
            ></div>
            <div
                :class="{ active: activeStep.step === 4, prev: activeStep.step > 4 }"
                class="stateCircle"
            ></div>
        </div>

        <div v-if="activeStep.currentViewTitle != null" class="titleBox mb-5">
            {{ activeStep.currentViewTitle }}
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted } from 'vue'
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'

export default {
    name: 'OnBoarding',
    props: {
        SelectCRMSystem: {
            type: Boolean,
            default: false,
        },
        AuthorizeCRMSystem: {
            type: Boolean,
            default: false,
        },
        CRMSystemSetting: {
            type: Boolean,
            default: false,
        },
        BotSetting: {
            type: Boolean,
            default: false,
        },
        Successful: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const router = useRouter()
        const root = getCurrentInstance().proxy
        const { project } = useProject()
        onMounted(async () => {
            const isOnBoardingActive = computed(() => project.value?.isOnBoardingActive)

            if (!isOnBoardingActive.value) {
                router.router.push(`/project/${project.value.id}`)
                return
            }

            const basisConnector = project.value.connectors.find(
                (connector) => connector.connectorType === 'whatsappBasis'
            )

            const isNeedToGoOnBot =
                (!basisConnector || basisConnector?.isDraft === true) &&
                project.value.connectors?.filter((a) => a.connectorId).length > 0

            if (isNeedToGoOnBot) {
                router.router.push(`/project/${project.value.id}/onBoarding/BotSettingsView`)
                return
            }

            const isNeedToGoOnProject = project.value.connectors.some(
                (connector) => connector.connectorType === 'whatsappBasis'
            )

            console.log({ isNeedToGoOnBot, isNeedToGoOnProject })
            if (isNeedToGoOnProject) {
                router.router.push(`/project/${project.value.id}`)
            }
        })

        if (router.route.value.fullPath.split('/').at(-1) === 'onBoarding')
            router.router.push(`${router.route.value.fullPath}/SelectCRMSystem`)

        const activeStep = computed(() => {
            if (props.SelectCRMSystem) return { step: 1, currentViewTitle: 'Выберите вашу CRM систему' }
            if (props.AuthorizeCRMSystem) return { step: 2, currentViewTitle: 'Авторизуйтесь в CRM системе' }
            if (props.CRMSystemSetting) return { step: 3, currentViewTitle: 'Настройки CRM системы' }
            if (props.BotSetting) return { step: 4, currentViewTitle: 'Настройки бота' }
            if (props.Successful) return { step: 5, currentViewTitle: null }
        })

        return {
            activeStep,
        }
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.markers
    width: 100%
    .titleBox
        width: 100%
        font-size: 34px
        text-align: left
    .stateCircle
        width: 24px
        height: 24px
        border-radius: 50%
        background-color: $light-gray-4
        margin: 5px
    .active
        border: 2px solid $main-light-2
    .prev
        background-color: $main-light-2
    .stateStatusCircles
        display: flex
        flex-direction: row
        width: 100%
        align-items: center
        justify-content: center
        margin: 30px 0
</style>